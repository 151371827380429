// themecolor
$themecolor: #062ee3;

$primarybtn: #062ee3;

$white: #fff;

$mate-black: #212121;

$black: #474646;

$gradient-one: #062ee3;

$gradient-two: #8a51e0;

$footer-back-color:#000;

$header-top-color:#000;

$breadcrumbs-color:#000;
